<template>
  <div :id="`${tableName}-table-top`" class="m-1 mb-2">
    <b-row align-h="between" align-v="end">
      <b-col
        :cols="filtersXs"
        :md="nbActions ? undefined : 12"
        :order="!showSearch ? 1 : 2"
        :order-sm="!showSearch || nbActions <= 1 ? 1 : 2"
        :sm="filtersSm"
        class="mt-50"
        lg="7"
        order-lg="1"
      >
        <b-row :align-h="showSearch && nbActions !== 1 ? 'between' : 'start'" align-v="end">
          <!-- PER PAGE -->
          <div v-if="showPerPage" class="d-inline-flex px-1">
            <b-form-select
              :id="`${tableName}-table-top-per-page`"
              :disabled="perPageDisabled || disabled"
              :options="perPageOptions"
              :value="perPage"
              class="per-page-selector"
              @change="$emit('table-select-per-page', $event)"
            />
          </div>

          <!-- SEARCH-->
          <b-col v-if="showSearch" order="2">
            <slot name="search">
              <app-data-table-top-search
                :condensed="!showFilter && nbActions > 1 ? 'sm' : undefined"
                :default-filter="computedDefaultFilter"
                :disabled="searchDisabled || disabled"
                :filter-options="searchFilterOptions"
                :search-placeholder="searchPlaceholder"
                :search-text="$attrs.searchText"
                :search-field-type="searchFieldType"
                :table-name="tableName"
                @filter="$emit('table-search-filter', $event)"
                @search="$emit('table-search-text', $event)"
              />
            </slot>
          </b-col>

          <!-- FILTER -->
          <b-col
            v-if="showFilter"
            :class="['d-flex justify-content-lg-start', { 'justify-content-end': showSearch && nbActions !== 1 }]"
            :sm="showSearch ? 3 : ''"
            cols="2"
            order="3"
          >
            <app-data-table-top-filter
              :condensed="condensedFilter"
              :disabled="filterDisabled || disabled"
              :filters-count="filtersCount"
              :table-name="tableName"
              @filter="$emit('table-filter', $event)"
            />
          </b-col>
        </b-row>
      </b-col>

      <!-- ACTIONS -->
      <b-col
        :cols="actionsXs"
        :order="!showSearch ? 2 : 1"
        :order-sm="!showSearch || nbActions <= 1 ? 2 : 1"
        :sm="actionsSm"
        md=""
        class="mb-lg-0"
        lg="5"
        order-lg="2"
      >
        <slot name="actions">
          <app-data-table-top-actions
            :add-disabled="addDisabled"
            :clear-selected-disabled="clearSelectedDisabled"
            :disabled="disabled"
            :export-disabled="exportDisabled"
            :hide-btn-text="hideBtnText"
            :import-disabled="importDisabled"
            :select-all-disabled="selectAllDisabled"
            :show-add="showAdd"
            :show-clear-selected="showClearSelected"
            :show-export="showExport"
            :show-import="showImport"
            :show-select-all="showSelectAll"
            :size="size"
            :table-name="tableName"
            condensed="md"
            @add="$emit('table-add')"
            @export="$emit('table-export')"
            @import="$emit('table-import')"
            @select-all="$emit('table-select-all')"
            @clear-selected="$emit('table-clear-selected')"
          />
        </slot>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppDataTableTopSearch from '@/components/AppDataTableTopSearch.vue'
import AppDataTableTopFilter from '@/components/AppDataTableTopFilter.vue'
import AppDataTableTopActions from '@/components/AppDataTableTopActions.vue'

export default {
  name: 'AppDataTableTop',

  components: {
    AppDataTableTopSearch,
    AppDataTableTopFilter,
    AppDataTableTopActions,
  },

  props: {
    tableName: {
      type: String,
      default: '',
    },
    perPage: {
      type: Number,
      default: 10,
    },
    perPageOptions: {
      type: Array,
      default: () => [5, 10, 25],
    },
    searchFilterOptions: {
      type: Array,
      default: () => [],
    },
    searchDefaultFilter: {
      type: String,
      default: '',
    },
    searchFieldType: {
      type: String,
      default: undefined,
    },
    searchPlaceholder: {
      type: String,
      default: null,
    },
    filtersCount: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showPerPage: {
      type: Boolean,
      default: true,
    },
    perPageDisabled: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    searchDisabled: {
      type: Boolean,
      default: false,
    },
    showFilter: {
      type: Boolean,
      default: false,
    },
    filterDisabled: {
      type: Boolean,
      default: false,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
    addDisabled: {
      type: Boolean,
      default: false,
    },
    showExport: {
      type: Boolean,
      default: false,
    },
    exportDisabled: {
      type: Boolean,
      default: false,
    },
    showImport: {
      type: Boolean,
      default: false,
    },
    importDisabled: {
      type: Boolean,
      default: false,
    },
    showSelectAll: {
      type: Boolean,
      default: false,
    },
    selectAllDisabled: {
      type: Boolean,
      default: false,
    },
    showClearSelected: {
      type: Boolean,
      default: false,
    },
    clearSelectedDisabled: {
      type: Boolean,
      default: false,
    },
    condensed: {
      type: String,
      default: 'lg',
    },
    size: {
      type: String,
      default: 'md',
    },
  },

  computed: {
    computedDefaultFilter() {
      if (this.searchDefaultFilter || !this.searchFilterOptions[0]) return this.searchDefaultFilter
      return this.searchFilterOptions[0].value
    },
    nbActions() {
      let number = 0
      const btnActions = ['showAdd', 'showImport', 'showExport']
      btnActions.forEach(action => {
        if (this[action]) number++
      })
      return number
    },
    filtersXs() {
      if (this.showSearch || !this.nbActions) return 12
      if (this.nbActions === 1) return 7
      return 5
    },
    filtersSm() {
      if (!this.showSearch) return 5
      if (this.nbActions !== 1) return 12
      return 9
    },
    actionsXs() {
      if (this.showSearch || !this.nbActions) return 12
      if (this.nbActions === 1) return 5
      return 7
    },
    actionsSm() {
      if (!this.showSearch) return 7
      if (this.nbActions !== 1) return 12
      return 3
    },
    hideBtnText() {
      if (!this.showSearch && this.$store.getters['app/smAndDown']) {
        if (this.showFilter) return this.nbActions > 1
        if (this.nbActions > 2) return true
      }
      return false
    },
    condensedFilter() {
      if (!this.showSearch) {
        if (!this.hideBtnText && this.$store.getters['app/smAndDown']) return 'sm'
        if (this.nbActions === 1) return 'xs'
      }
      return this.nbActions === 1 ? 'md' : 'sm'
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 65px;
}
</style>
