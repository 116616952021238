<template>
  <div :id="`${tableName}-top-filter`" class="d-flex align-items-end justify-content-center">
    <b-button
      :id="`${tableName}-top-filter-btn`"
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      class="px-50 px-sm-2"
      variant="outline-secondary"
      :disabled="disabled"
      :class="{ 'btn-filter': filtersCount !== 0 }"
      @click="toggleFilter()"
    >
      <div class="d-flex justify-content-center align-items-center">
        <template v-if="$store.getters[`app/${breakpoint}AndDown`]">
          <feather-icon icon="FilterIcon" class="mr-25" />
          <feather-icon :icon="filter ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
        </template>
        <!-- TODO: trad -->
        <span v-else class="text-nowrap"> {{ filter ? 'Hide filters' : ' Show filters' }}</span>
        <b-badge v-show="filtersCount !== 0" pill variant="primary" class="ml-25">{{ filtersCount }}</b-badge>
      </div>
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'AppDataTableTopFilter',

  props: {
    tableName: {
      type: String,
      default: 'lg',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filtersCount: {
      type: Number,
      default: 0,
    },
    condensed: {
      type: String,
      default: 'lg',
    },
  },

  data() {
    return {
      filter: false,
    }
  },

  computed: {
    breakpoint() {
      return ['sm', 'xs', 'md', 'lg', 'xl'].indexOf(this.condensed) !== -1 ? this.condensed : 'lg'
    },
  },

  watch: {},

  mounted() {},

  methods: {
    toggleFilter() {
      this.filter = !this.filter
      this.$emit('filter', this.filter)
    },
  },
}
</script>

<style lang="scss" scoped>
.btn .badge {
  position: static;
}

.btn-filter {
  padding: 0.55rem 0.736rem;
}
</style>
