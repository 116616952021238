<template>
  <div :id="`${tableName}-data-table-top-actions`" class="d-flex justify-content-end">
    <!--add-->
    <app-btn-add v-if="showAdd" :disabled="addDisabled || disabled" :block="isBlockBtn" :size="size" :hide-text="hideBtnText" @click.prevent="$emit('add')" />
    <!--export-->
    <b-button
      v-if="showExport"
      :id="`${tableName}-data-table-top-action-export`"
      v-ripple.400
      :class="{ 'ml-50 ml-md-1': showAdd }"
      variant="outline-primary"
      :disabled="exportDisabled || disabled"
      :block="isBlockBtn"
      :size="size"
      @click.prevent="$emit('export')"
    >
      <span class="text-nowrap">
        <feather-icon icon="UploadIcon" />
        <span v-if="!hideBtnText" class="ml-25">{{ $t('action.export') }}</span>
      </span>
    </b-button>
    <!--import-->
    <b-button
      v-if="showImport"
      :id="`${tableName}-data-table-top-action-import`"
      v-ripple.400
      class="ml-50 ml-md-1"
      variant="outline-primary"
      :disabled="importDisabled || disabled"
      :block="isBlockBtn"
      :size="size"
      @click.prevent="$emit('import')"
    >
      <span class="text-nowrap">
        <feather-icon icon="DownloadIcon" />
        <span v-if="!hideBtnText" class="ml-25">{{ $t('action.import') }}</span>
      </span>
    </b-button>
    <!--select all-->
    <b-button
      v-if="showSelectAll"
      :id="`${tableName}-data-table-top-action-select-all`"
      v-ripple.400
      variant="outline-primary"
      :disabled="selectAllDisabled || disabled"
      :block="$store.getters[`app/${breakpoint}AndDown`]"
      :size="size"
      @click.prevent="$emit('select-all')"
    >
      <span class="text-nowrap">
        {{ $t('action.select_all') }}
      </span>
    </b-button>
    <!--clear selected-->
    <b-button
      v-if="showClearSelected"
      :id="`${tableName}-data-table-top-action-clear-selected`"
      v-ripple.400
      variant="outline-primary"
      :disabled="clearSelectedDisabled || disabled"
      :block="$store.getters[`app/${breakpoint}AndDown`]"
      :size="size"
      class="w-100"
      @click.prevent="$emit('clear-selected')"
    >
      <span class="text-nowrap">
        {{ $t('action.clear_selected') }}
      </span>
    </b-button>
  </div>
</template>

<script>
import AppBtnAdd from './AppBtnAdd.vue'

export default {
  name: 'AppDataTableTopActions',
  components: { AppBtnAdd },

  props: {
    tableName: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
    addDisabled: {
      type: Boolean,
      default: false,
    },
    showExport: {
      type: Boolean,
      default: false,
    },
    exportDisabled: {
      type: Boolean,
      default: false,
    },
    showImport: {
      type: Boolean,
      default: false,
    },
    importDisabled: {
      type: Boolean,
      default: false,
    },
    showSelectAll: {
      type: Boolean,
      default: false,
    },
    selectAllDisabled: {
      type: Boolean,
      default: false,
    },
    showClearSelected: {
      type: Boolean,
      default: false,
    },
    clearSelectedDisabled: {
      type: Boolean,
      default: false,
    },
    hideBtnText: {
      type: Boolean,
      default: false,
    },
    condensed: {
      type: String,
      default: 'lg',
    },
    size: {
      type: String,
      default: 'md',
    },
  },

  computed: {
    breakpoint() {
      return ['sm', 'xs', 'md', 'lg', 'xl'].indexOf(this.condensed) !== -1 ? this.condensed : 'lg'
    },
    isBlockBtn() {
      if (!this.$store.getters[`app/${this.breakpoint}AndDown`]) return false
      let number = 0
      const btnActions = ['showAdd', 'showImport', 'showExport']
      btnActions.forEach(action => {
        if (this[action]) number++
      })
      return number > 1
    },
  },
}
</script>

<style lang="scss" scoped>
[dir] .btn-block + .btn-block {
  margin-top: 0;
}
</style>
